import { all } from 'typed-redux-saga'

import watchFiles from './attachFiles/saga'
import watchHealthcare from './healthcare/saga'
import watchMedicines from './medicines/saga'
import watchScheduling from './scheduling/saga'
import watchSpecialties from './specialties/saga'
import watchSubscription from './subscription/saga'
import watchSymptoms from './symptoms/saga'

export default function* rootSaga() {
  return yield* all([
    watchSymptoms(),
    watchMedicines(),
    watchSpecialties(),
    watchSubscription(),
    watchHealthcare(),
    watchScheduling(),
    watchFiles(),
  ])
}
