import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import PDFUrlDictionary from 'src/enums/PdfUrlDictionary'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { BtnLink, H2, PMedium, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIBottomSheet,
  InterUICheckBox,
  InterUIContainer,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { ContractTerms } from './CancellationConditions.styles'

export const CancellationConditions: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const isLoading = useSelector(LoadingActions.get)

  const [acceptTerms, setAcceptTerms] = useState(false)
  const [confirmCancellationBS, setConfirmCancellationBS] = useState(false)

  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconManual = <Icons.BookOpen color={theme.colors.neutral.grayscale.A500} />

  const { hasCancellationFee } = useSelector(SubscriptionActions.getCancellationFee)

  useBasePage({
    navbarTitle: PageTitles.CANCELLATION,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const openManual = () => {
    BaseBridge.openPdf(PDFUrlDictionary.MANUAL, 'Manual do Doutor Inter')
  }

  const checkCancellationFine = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ASSINATURA_CANCEL_T_CONTINUAR.name)

    if (hasCancellationFee) {
      history.push(TypesRoutes.CANCELLATION_FINE)
    } else {
      setConfirmCancellationBS(true)
    }
  }

  const openContractTerms = () => {
    BaseBridge.openPdf(PDFUrlDictionary.TERMS_CONDITIONS, 'Contrato do Doutor Inter')
  }

  const iSafe = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ASSINATURA_CANCEL_T_FINALIZAR.name)

    dispatch(
      SubscriptionActions.cancelSubscription({
        history,
        pathname: TypesRoutes.CANCELLATION_SUCCESS,
      }),
    )
    setConfirmCancellationBS(false)
  }

  const stickyFooter = (
    <>
      <InterUIListDescription
        iconLeft={iconManual}
        iconRight={iconArrow}
        margin="24px"
        alignAllItems="center"
        onClick={openManual}
      >
        <PMedium marginBottom="0" bold>
          Manual do produto
        </PMedium>
        <PMedium marginBottom="0" scale={400}>
          Doutor Inter
        </PMedium>
      </InterUIListDescription>

      <InterUISeparator height="1px" />

      <InterUICheckBox
        value=""
        name="no-number"
        id="no-number"
        onChange={() => setAcceptTerms(!acceptTerms)}
        checked={acceptTerms}
        margin="2px 24px"
      >
        <ContractTerms>
          <PSmall marginBottom="0">
            Li e concordo com as informações descritas do documento nos
            <BtnLink onClick={openContractTerms}> Termos e Condições Gerais do Produto </BtnLink>e
            estou de acordo com as condições de cancelamento.
          </PSmall>
        </ContractTerms>
      </InterUICheckBox>

      <Button
        data-testid="button-continue"
        margin="0 24px 24px"
        disabled={!acceptTerms || isLoading}
        onClick={() => checkCancellationFine()}
      >
        Continuar
      </Button>
    </>
  )

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIContainer margin="24px">
          <H2 marginBottom="8px">Termos e condições gerais</H2>
          <PMedium marginBottom="8px" scale={400}>
            Antes de continuar, você precisa ler e concordar com as condições de cancelamento.
          </PMedium>
        </InterUIContainer>
      </InterUIContainer>

      <InterUIBottomSheet
        data-testid="confirm-cancellation-bottom-sheet"
        toggle={confirmCancellationBS}
        onHide={() => setConfirmCancellationBS(false)}
        title="Confirmar transação"
      >
        <PMedium scale={400} marginBottom="32px">
          Confirme a transação para autenticá-la com o i-safe.
        </PMedium>
        <Button variant="primary" onClick={iSafe} disabled={isLoading}>
          Finalizar cancelamento
        </Button>
      </InterUIBottomSheet>
    </>
  )
}
