import {
  AccreditedNetwork,
  AppointmentCancellation,
  AppointmentCancellationConfirmed,
  AppointmentConfirmation,
  CaptureImage,
  Maintenance,
  WaitingRoom,
} from 'src/components'
import {
  AppointmentDate,
  Payment,
  Professional,
  Specialties,
} from 'src/pages/appointmentScheduling'
import {
  Appointment,
  AttachFiles,
  Medicines,
  OtherSymptoms,
  PaymentConsult,
  PrepareConsult,
  Symptoms,
  WaitingTime,
} from 'src/pages/emergency'
import { DrInter, Start } from 'src/pages/home'
import {
  Cancellation,
  CancellationConditions,
  CancellationFine,
  CancellationReason,
  CancellationSuccess,
  MySubscriptionDetails,
} from 'src/pages/profile'
import { CancellationFineSuccess } from 'src/pages/profile/Cancellation/CancellationFineSuccess/CancellationFineSuccess'
import {
  CancelledCardDetails,
  Reschedule,
  RescheduleDetails,
  ScheduledCardDetails,
  UnrealizedCardDetails,
} from 'src/pages/schedule'
import { HomeSubscription, SubscriptionDetails, SubscriptionSummary } from 'src/pages/subscription'

import { TypesRoutes as Routes } from './types'

export const mixedRoutes = [
  // HOME
  { route: Routes.START, component: Start },
  { route: Routes.HOME_DR_INTER, component: DrInter },

  // EMERGENCY_CARE
  { route: Routes.SYMPTOMS, component: Symptoms },
  { route: Routes.OTHER_SYMPTOMS, component: OtherSymptoms },
  { route: Routes.MEDICINES, component: Medicines },
  { route: Routes.PAYMENT_CONSULT, component: PaymentConsult },
  { route: Routes.ATTACH_FILES, component: AttachFiles },
  { route: Routes.PREPARE_CONSULT, component: PrepareConsult },
  { route: Routes.WAITING_TIME, component: WaitingTime },
  { route: Routes.APPOINTMENT, component: Appointment },

  // PROFILE
  { route: Routes.MY_SUBSCRIPTION_DETAILS, component: MySubscriptionDetails },
  { route: Routes.CANCELLATION_SUBSCRIPTION, component: Cancellation },
  { route: Routes.CANCELLATION_REASON, component: CancellationReason },
  { route: Routes.CANCELLATION_CONDITIONS, component: CancellationConditions },
  { route: Routes.CANCELLATION_SUCCESS, component: CancellationSuccess },
  { route: Routes.CANCELLATION_FINE, component: CancellationFine },
  { route: Routes.CANCELLATION_FINE_SUCCESS, component: CancellationFineSuccess },

  // SCHEDULE
  { route: Routes.SCHEDULED_DETAILS, component: ScheduledCardDetails },
  { route: Routes.CANCELLED_DETAILS, component: CancelledCardDetails },
  { route: Routes.UNREALIZED_DETAILS, component: UnrealizedCardDetails },
  { route: Routes.RESCHEDULE, component: Reschedule },
  { route: Routes.RESCHEDULE_DETAILS, component: RescheduleDetails },

  // SCHEDULING
  { route: Routes.SPECIALTIES, component: Specialties },
  { route: Routes.PROFESSIONAL, component: Professional },
  { route: Routes.APPOINTMENT_DATE, component: AppointmentDate },
  { route: Routes.PAYMENT, component: Payment },

  // SUBSCRIPTION
  { route: Routes.SUBSCRIPTION, component: HomeSubscription },
  { route: Routes.SUBSCRIPTION_SUMMARY, component: SubscriptionSummary },
  { route: Routes.SUBSCRIPTION_DETAILS, component: SubscriptionDetails },

  // COMPONENTS
  { route: Routes.APPOINTMENT_CANCELLATION, component: AppointmentCancellation },
  { route: Routes.APPOINTMENT_CANCELLATION_CONFIRMED, component: AppointmentCancellationConfirmed },
  { route: Routes.APPOINTMENT_CONFIRMATION, component: AppointmentConfirmation },
  { route: Routes.WAITING_ROOM, component: WaitingRoom },
  { route: Routes.TAKE_THE_PICTURE, component: CaptureImage },
  { route: Routes.ACCREDITED_NETWORK, component: AccreditedNetwork },
  { route: Routes.MAINTENANCE, component: Maintenance },
]

export * from './types'
