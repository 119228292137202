import { combineReducers } from 'redux'

import attachFiles from './attachFiles'
import checkout from './checkout'
import error from './error'
import healthcare from './healthcare'
import loading from './loading'
import medicines from './medicines'
import navbar from './navbar'
import pathparams from './pathparams'
import persistedCache from './persistedCache'
import { persistedPersistedData } from './persistedData'
import scheduling from './scheduling'
import specialties from './specialties'
import subscription from './subscription'
import symptoms from './symptoms'
import tab from './tab'
import theme from './theme'
import user from './user'

const rootReducer = combineReducers({
  navbar,
  loading,
  symptoms,
  medicines,
  attachFiles,
  specialties,
  subscription,
  healthcare,
  scheduling,
  error,
  tab,
  checkout,
  pathparams,
  persistedCache,
  persistedData: persistedPersistedData,
  theme,
  user,
})

export default rootReducer
