import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { reasonCancellation } from 'src/mocks/ReasonCancellation'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { H2, PMedium } from 'src/styles/commons'

import { InterUIContainer, InterUIDropdown, InterUIInputGroup } from '@interco/inter-ui-react-lib'

import { Button } from '..'

import { ContentDropdown } from './AppointmentCancellation.styles'

export const AppointmentCancellation: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isLoading = useSelector(LoadingActions.get)
  const getRedirectCancel = useSelector(HealthcareActions.getRedirectToCancel)

  const [reasonLabel, setReasonLabel] = useState('')
  const [reasonValue, setReasonValue] = useState('')
  const [reasonText, setReasonText] = useState('')
  const [textLength, setTextLength] = useState(0)

  const reasonOpts = reasonCancellation.map((item, index) => ({
    label: item.reason,
    value: index.toString(),
  }))

  useBasePage({
    navbarTitle: PageTitles.CANCELLATION,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const onChangeReason = (value?: string | number | string[] | number[]) => {
    if (value) {
      const selected = reasonOpts[+value].label

      setReasonLabel(selected)
      setReasonValue(value.toString())
      setReasonText('')
      setTextLength(0)
    }
  }

  const onContinue = () => {
    if (getRedirectCancel.type === 'IMMEDIATE') {
      reasonLabel !== 'Outro'
        ? BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CANCELAMENTO_T_CANCELARPA.name, {
            motivo_cancel: reasonLabel,
          })
        : BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CANCELMOTIVO_T_CANCELAR.name, {
            motivo_cancel: reasonText,
          })
      dispatch(
        HealthcareActions.cancelAttendance({
          history,
          pathname: TypesRoutes.APPOINTMENT_CANCELLATION_CONFIRMED,
          reason: reasonLabel === 'Outro' ? `Outro - ${reasonText}` : reasonLabel,
        }),
      )
    } else {
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CANCELAMENT_T_CANCELAR.name, {
        motivo_cancel: reasonLabel === 'Outro' ? `Outro - ${reasonText}` : reasonLabel,
      })

      dispatch(
        SchedulingActions.cancelScheduling({
          history,
          pathname: TypesRoutes.APPOINTMENT_CANCELLATION_CONFIRMED,
        }),
      )
    }
  }

  /**
   * Evento disparado ao digitar informações no input de textarea.
   * @param event ChangeEvent disparado.
   */
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target as HTMLTextAreaElement

    setReasonText(value)
    setTextLength(value.length)
  }

  const stickyFooter = (
    <Button onClick={onContinue} disabled={!reasonValue || isLoading}>
      Cancelar consulta
    </Button>
  )

  return (
    <>
      <InterUIContainer stickyFooter={stickyFooter}>
        <H2 marginBottom="24px">Motivo do cancelamento</H2>

        <ContentDropdown>
          <InterUIDropdown
            margin="0 0 16px "
            hideSearch
            name="reason"
            label=""
            labelOptions="Motivo do cancelamento"
            placeholder="Selecione o motivo"
            options={reasonOpts}
            value={reasonValue}
            onChange={onChangeReason}
            disabled={isLoading}
          />
        </ContentDropdown>

        {reasonLabel === 'Outro' && (
          <>
            <InterUIInputGroup>
              <textarea
                id="textarea"
                placeholder="Escreva o motivo aqui"
                rows={6}
                value={reasonText}
                maxLength={500}
                onChange={(event) => handleChange(event)}
              />
            </InterUIInputGroup>
            <PMedium scale={400} margin="4px 0 0">{`${textLength}/500 caracteres`}</PMedium>
          </>
        )}
      </InterUIContainer>
    </>
  )
}
